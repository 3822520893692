






















































































































































































































































































































































import { Component, Vue } from "vue-property-decorator"
import FastGameMenu from "@/components/menus/FastGameMenu.vue"
import { namespace } from "vuex-class"
import Common from "../../mixins/Common.vue"
import KenoNum from "@/components/macros/KenoNum.vue"
import axios from 'axios'
const UIMutation = namespace("ui").Mutation
const SettingsGetter = namespace("settings").Getter
const AccountGetter = namespace('account').Getter

const AppProps = Vue.extend({
  props: {},
  data: () => {
    return {
      valid_credit_sizes: [],
      credit_btc_value: 0,
      paytable: [0, 0, 0, 0, 1, 3, 7, 25, 120, 500, 1000],
      hit_scarab_extra_blessings: 3,
      hit_scarab_prize_multiplier: 3,
      isAccountLoaded: false
    };
  },
  mixins: [Common],
  methods: {
    async getRuleSet() {
      try {
        let res = await axios.get('/api/keno/ruleset')
        if(res.data.result) {
          this.valid_credit_sizes = res.data.result.valid_credit_sizes
          this.getPlatformCurrency == "BTC" ? this.credit_btc_value = 1000 : this.credit_btc_value = 50000
          return res.data.result
        } else {
          throw new Error('invalid response')
        }
      }
      catch(e) {
        this.$router.push('maintenance')
        console.log("something went wrong when trying to get the ruleset")
        return e
      }
    },
    async reseed() {
      try {
        let res = await axios.post('/api/keno/seed')
        if(res.data.server_seed_hash) {
          return res.data.server_seed_hash
        } else {
          throw new Error('invalid response')
        }
      }
      catch(e) {
        console.log("something went wrong when trying to reseed")
        return e
      }
    },
    async initGame() {
      this.isAccountLoaded = true;
      this.initializeGameScale()
      // @ts-ignore
      // eslint-disable-next-line no-undef
      await this.$loadScript('/js/legacy/keno.js');
      try {
        let ruleset = await this.getRuleSet()
        let starting_server_seed_hash = await this.reseed()
        let free_spin_info = { 'left': 0 }
        let creditModal = "#btc_item_" + this.classSuffixFromToken(this.credit_btc_value)
        $(creditModal).addClass("selected");
        // @ts-ignore
        // eslint-disable-next-line no-undef
        init_keno("123", "123", "123", starting_server_seed_hash, [], [], [], ruleset, this.credit_btc_value, 10, free_spin_info);
      } catch (e) {
        console.log("error", e)
      }


      // @ts-ignore
      this.setFooterDisabled(true);
      this.setBalanceDisabled(false)
      // $(document.body).addClass("small");
    }
  },
  created() {
    // @ts-ignore
    this.setFooterDisabled(true);
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Play Exclusive Triple Bonus Keno Online with Bitcoin, Win Big Jackpots & Cashout Instantly at the best Bitcoin Games Casino`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `Keno`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
  watch: {
   
  },
  async mounted() {
    // note that its possible the usertoken is not yet available,
    // in this case the watcher will init keno game when the token
    // is available
    this.initGame();
  }
});
@Component({
  components: {
    FastGameMenu,
    KenoNum
  }
})
export default class Keno extends AppProps {
  @UIMutation('setFooterDisabled') setFooterDisabled
  @UIMutation('setBalanceDisabled') setBalanceDisabled
  @SettingsGetter getPlatformCurrency
  @AccountGetter userToken

}

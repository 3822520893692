








import { Component, Vue } from "vue-property-decorator";

const AppProps = Vue.extend({
  props: ["id"]
})
@Component({
  components: {}
})
export default class KenoNum extends AppProps {}
